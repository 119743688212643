import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.jpg";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                </div>

                <div className="section-main-description">
                <p>National Storage Affiliates Core Values are Compassion, Humility, Accountability, Integrity</p>
                <p>The Compassion Fund supports current employees when they face extraordinary financial hardships.</p>
                <p>The Compassion Fund embraces NSA’s value of compassion and strives to provide support and aid in times of unforeseen financial crises that can be devasting to our employee’s overall well-being.</p>
                <p>The Compassion Fund will provide support for a wide range of situations that colleagues might face:</p>
                <p>You face an unusual level of hardship or a personal crisis due to circumstances outside of your control.</p>
                <p>You need emergency support following a natural disaster.</p>
                <p>You are facing extraordinary costs associated with medical needs due to your health, well-being, or recovery. Your generosity, should you choose to donate to this fund, will support NSA employees and their families when they need it most.</p>
                <p>Aside from the minimal credit card processing fees, all proceeds will go directly to our employees.</p>
                <p>Donations and employee grants will be handled confidentially.</p>
                <p>All donations are tax-deductible.</p>
                <p>Thank you for your generosity.</p>
                <p>NSA Storage Compassion Fund</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <center>
              </center>
              <Donate />
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
